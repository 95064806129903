import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const InfoPage = () => (
    <Layout>
        <SEO title="Tietoa palvelusta" description="Tietosuoja ja tietoa inttiruoka.fi palvelusta" />
        <h1>Tietosuoja ja tietoa palvelusta</h1>
        <p>
            Muutokset ruokalistoilla ovat mahdollisia, tarkistathan allergiat aina keittiöhenkilökunnalta.
        </p>
        <p>
            <strong>Henkilötietojen käsittely</strong><br/>
            Tämä sivusto ei kerää henkilötietoja.
        </p>
        <p>
            <strong>Evästeet</strong><br/>
            Sivustollamme käytetään evästeitä (cookies). Evästeet ovat tiedostoja, joita voidaan käyttää antamiesi tietojen säilyttämiseksi. Evästeet sisältävät nimettömän, numeerisen tunnisteen, jonka avulla voimme yksilöidä ja laskea sivustollamme vierailevat selaimet.
        </p>
        <p>
            <strong>Evästeiden käyttö</strong><br/>
            Osa evästeistä on välttämättömiä, jotta verkkosivut toimivat oikein, osa taas parantaa sivujen toiminnallisuutta ja käytettävyyttä. Evästeet mahdollistavat esimerkiksi sen, että käyttäjän ei tarvitse sivustolla vieraillessa aina valita omaa ravintolaa. Osa evästeistä kerää tietoa sivuston käytöstä (statistiikkaa), jotta sivustoa voidaan kehittää entistä käyttäjäystävällisemmäksi ja hyödyllisemmäksi.
            <br/><br/>
            Hyödynnämme evästeitä ja muita vastaavia tekniikoita (Google Analytics) myös kävijämäärien tilastollisessa seurannassa. Sivuston käytön analyysitietoa ei käsitellä tai talleteta siten, että yksittäinen käyttäjä olisi tunnistettavissa.
            <br/><br/>
            Sivustollamme voi olla käytössä myös toimintoja kuten Twitterin Seuraa-linkki. Twitter ja muut vastaavat palveluntarjoajat voivat kerätä tietoja kävijän vierailusta kulloinkin voimassaolevien ehtojensa mukaisesti.                        
        </p>
        <p>
            <strong>Evästeiden hallinta</strong><br/>
            Mikäli käyttäjä ei halua, että me tai yhteistyökumppanimme kohdistamme mainontaa tai sisältöä internet-käyttäytymisen perusteella, käyttäjä voi estää tämän kieltämällä haluamansa evästeet selaimensa asetuksista. Evästettä vastaavan mobiilitunnisteen voi nollata laitteen asetuksista. 
            <br/><br/>
            Tyhjentämällä evästeet tai mobiilitunnisteen säännöllisin väliajoin käyttäjä vaihtaa tunnistetta, jonka pohjalta käyttäjästä muodostuu profiili. Evästeiden tyhjentäminen ei kuitenkaan lopeta kokonaan tiedon keruuta, vaan nollaa aikaisempiin käyttäytymistietoihin pohjautuvan profiilin.
        </p>
        <p>
        <strong>Mainokset</strong><br/>
            Kolmannen osapuolen palveluntarjoajat, myös Google, käyttävät evästeitä. Niiden avulla ne näyttävät mainoksia sellaisten tietojen perusteella, jotka on saatu käyttäjän aiemmista vierailuista sivustollasi tai muilla sivustoilla.
            <br/><br/>
            Googlen mainosevästeiden avulla Google ja sen kumppanit voivat näyttää mainoksia käyttäjille niiden tietojen perusteella, jotka on saatu käyttäjien käynneistä sivustoillasi ja/tai muilla sivustoilla.
            <br/><br/>
            Käyttäjät voivat poistaa räätälöidyn mainonnan käytöstä <a href="https://www.google.com/settings/ads" target="_blank" rel="noreferrer">Mainosasetuksissa</a>.
        </p>
        <p>
            <strong>Muutokset tietosuojakäytäntöön</strong><br/>
            Voimme muuttaa ja päivittää tätä tietosuojakäytäntöä, jotta se heijastaisi mahdollisia muutoksia siihen, miten me käsittelemme sinua koskevia tietoja. Tarvittaessa teemme päivityksen tietosuojakäytäntöön, joka on nähtävissä verkkosivustollamme.
        </p>
        <p>
            Lähetä <Link to="/palaute">palautetta</Link>.
        </p>
        <p>
            Palvelun logot: <a href="https://www.freepik.com" title="Freepik">Freepik</a> osoitteesta <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a><br/>
            Google Play ja Google Play logo ovat Google LLC tavaramerkkejä.
        </p>
    </Layout>
)

export default InfoPage


